import { useEffect } from 'react';
import Announcement from '../../components/Announcement/Announcement';
// import YardSign from '../../components/YardSign/YardSign';
import { autoScroll } from '../../utils/animation';
import { titleHelper } from '../../utils/navigation';
import logo from '../../images/andrew-for-school-board--circle-2.png';

export default function Home() {
  useEffect(() => {
    document.title = titleHelper();

    autoScroll('#candidacy-information', 2000, 1000);
  }, []);

  return (
    <>
      <div className="h-full w-full flex justify-center justify-items-center">
        <img
          src={logo}
          className="primary-logo mt-32 xl:mt-8"
          alt="Vote Andrew Ninneman for Union Grove Elementary School Board"
        />
      </div>

      <div id="candidacy-information" className="w-full bg-white/80 px-8">
        <Announcement />
      </div>

      {/* <YardSign /> */}
    </>
  );
}
