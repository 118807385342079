import { GlobeAltIcon } from '@heroicons/react/20/solid';
import NavLink from '../NavLink/NavLink';
import { navigation } from '../../utils/navigation';
import applicationInfo from '../../applicationInformation.json';
import { autoScroll } from '../../utils/animation';

export default function Footer() {

  return (
    <footer className="bg-white mt-16 border-t border-sky-500 shadow-inner" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-8 sm:pt-24 lg:px-8 lg:pt-16">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-2">
            <div className="flex space-x-6">
              {
                navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-blue-900 hover:text-indigo-300 hover:animate-pulse" target="_blank" rel="noreferrer">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))
              }
            </div>
            <div className="relative p-4">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>

              <div className="relative flex justify-center">
                {/* <span className="bg-white px-2 text-sm text-gray-500">Contact</span> */}
              </div>
            </div>
            <div className="flex">
              <a
                href="https://www.uges.k12.wi.us/page/school-board"
                className="relative inline-flex w-0 flex-1 items-center gap-x-3 text-sm font-semibold text-indigo-900 hover:text-indigo-300 hover:animate-pulse"
              >
                <GlobeAltIcon className="h-5 w-5 text-blue-900" aria-hidden="true" />
                Union Grove Elementary School Board of Education
              </a>
            </div>
            {/* <div className="flex">
              <a
                href="mailto:andrew@andrewschoolboard.com"
                className="relative inline-flex w-0 flex-1 items-center gap-x-3 text-sm font-semibold text-indigo-900 hover:text-indigo-300 hover:animate-pulse"
              >
                <EnvelopeIcon className="h-5 w-5 text-blue-900" aria-hidden="true" />
                andrew@andrewschoolboard.com
              </a>
            </div> */}
            {/* <div className="flex">
              <a
                href="tel:+12625159626"
                className="relative inline-flex w-0 flex-1 items-center gap-x-3 text-sm font-semibold hover:text-indigo-300 hover:animate-pulse"
              >
                <PhoneIcon className="h-5 w-5 text-blue-900" aria-hidden="true" />
                (262) 515-9626
              </a>
            </div> */}
          </div>
          <div className="mt-16 grid grid-cols-4 gap-8 xl:col-span-2 xl:mt-0">
            {
              navigation.routes.filter(item => item.live && !item.disabled).sort().map((item) => (
                <div key={item.name}>
                  <NavLink
                    to={item.href}
                    target={item.external ? '_blank' : '_self'}
                    className="text-base font-semibold leading-6 hover:text-indigo-300 hover:animate-pulse text-indigo-900"
                    onClick={() => autoScroll('#main', 0, 1000, '#main', 0)}
                  >
                    {item.name}
                  </NavLink>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="w-full mt-8 border-t border-white/10 bg-gray-900">
        <p className="font-mono text-lg text-center text-white font-bold p-8">
          Paid for by Andrew Ninneman for School Board.
        </p>
      </div>
      <div className="w-full mx-auto px-6 pb-8 bg-gray-900">
        <div className="border-t border-white/10 pt-8 flex justify-between">
          <p className="text-xs leading-5 text-gray-100">&copy; {applicationInfo.copyright}</p>
          {/* <p className="text-xs leading-5 text-gray-600">{`v${applicationInfo.version}`}</p> */}
        </div>
      </div>
    </footer>
  );
}
