import { useEffect } from 'react';
import { titleHelper } from "../../utils/navigation";
import headshot from '../../images/andrew-ninneman-for-union-grove-elementary-school-board--1--1.jpg';
import { navigation } from '../../utils/navigation';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

export default function AboutMe() {
  useEffect(() => {
    document.title = titleHelper('About Me');
  }, []);

  return (
    <div
      id="candidacy-information-about-me"
      className="bg-white flex-grow py-24 md:py-32 text-gray-600 font-medium"
    >
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-12 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-2xl xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About Me</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            I'm a proven leader, holding multiple leadership roles professionally, and in the Union Grove community.
            I am a technology professional, with strong technical experience, in an ever changing world of innovation.
            I care deeply about the community, our children's education and our schools.</p>
        </div>

        <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
          <li className="flex flex-col gap-10 pt-12 sm:flex-row">
            <img
              className="aspect-[2/3] w-52 flex-none rounded-2xl object-cover max-h-content"
              src={headshot}
              alt="Andrew Ninneman Profile"
            />
            <div className="max-w-xl flex-auto">
              <h3 className="text-lg font-bold leading-8 tracking-tight text-gray-900">
                Andrew Ninneman
              </h3>
              <p className="text-base leading-7 text-gray-600">
                School Board Candidate
              </p>
              <ul className="mt-6 flex gap-x-6">
                {
                  navigation.social.map(item => (
                    <li key={item.name}>
                      <a href={item.href} className="text-gray-400 hover:text-gray-500" target="_blank" rel="noreferrer">
                        <span className="sr-only">X</span>
                        <item.icon className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" />
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div>
          </li>
        </ul>

        <div className="mt-6 xl:col-span-5 col-span-1">
          <div>
            <h4 className="text-md font-bold leading-6 tracking-tight text-gray-900">
              Why have you chosen to seek election to the Union Grove Elementary School Board?
            </h4>
            <div className="mt-1 ms-4 leading-6 text-gray-600 font-medium">
              <p className="my-4">
                I have decided to run for school board because I want to continue to give back to a school and
                community that has done some much for me. I care deeply about the education and
                well-being of our students. I believe that every child deserves a high-quality, safe,
                and innovative learning environment that prepares them for the challenges and opportunities
                of the future.
              </p>

              <p className="my-4">
                As a parent of two children who attend Union Grove Elementary, as well as my spouse who is
                an educator, I have witnessed the dedication and professionalism of our teachers, staff,
                and administrators. I have also observed areas where there are opportunities we can improve and
                innovate, such as enhancing academic achievement, supporting student engagement and mental
                health, continue to grow STEM opportunities, and strengthening community involvement and transparency.
                Not to mention to ever evolving world of technology.
              </p>

              <p className="my-4">
                If elected, I will work collaboratively with all stakeholders to address these issues and
                ensure that our schools are safe, supportive, and successful for all students. I will also
                advocate for adequate and sustainable funding, transparent and accountable governance, and
                effective, fact, and evidence-based curriculum, policies and practices.
              </p>
            </div>
          </div>
          <div className="mt-8 mb-2">
            <h4 className="mt-6 text-md font-bold leading-6 tracking-tight text-gray-900">
              What experience do you have that will help you best represent Union Grove Elementary School electorate?
            </h4>
            <div className="mt-1 ms-4 leading-6 text-gray-600 font-medium">
              <p className="my-4">
                Being a long-time Union Grove resident, growing up here and attending Union Grove Elementary and Union Grove Union High
                Schools, now a parent of kids that attend Union Grove Elementary School, homeowner, and taxpayer I understand how important
                strong public schools are to our community. Aside from being a caring parent and resident, I feel I have the following unique set
                of skills and experience to add value to the current Union Grove Elementary School Board team:
              </p>

              <div className="ml-8 mt-4">
                <div className="flex items-center">
                  <CheckCircleIcon className=" text-blue-500 w-10 h-10" />
                  <h2 className="text-2xl font-black ml-2">LEADERSHIP</h2>
                </div>
                <p className="block ml-4 mt-2">
                  Being a technology leader professionally, in church leadership, and in numerous leadership positions within the community. I
                  have the experience to help the Union Grove Elementary School Board build and support their vision, data-driven decision-making,
                  collaboration, and relationship building based on mutual respect, effective communication, and exercising strong responsible
                  fiscal management. Professional and personal experience has given me the tools to work collaboratively with those who hold
                  differing points of view to reach fact-based solutions to complex problems.
                </p>
              </div>

              <div className="ml-8 mt-8">
                <div className="flex items-center">
                  <CheckCircleIcon className=" text-blue-500 w-10 h-10" />
                  <h2 className="text-2xl font-black ml-2">TECHNOLOGY</h2>
                </div>
                <p className="block ml-4 mt-2">
                  As a technology professional and leader, I understand the technology innovation and pressures our school, students, and
                  families are facing, and will continue to face in the future. Whether it be from Artificial Intelligence (AI), application
                  packages, to school cyber security, or demand for STEM-based curriculum. Technology moves fast, and having technology literacy
                  on the school board is <b><i>vital</i></b>. I have a strong technical background, experience, and unique skillset to add
                  needed knowledge and value to the Union Grove Elementary School Board.
                </p>
              </div>

              <div className="ml-8 mt-8 mb-4">
                <div className="flex items-center">
                  <CheckCircleIcon className=" text-blue-500 w-10 h-10" />
                  <h2 className="text-2xl font-black ml-2">COMMUNITY</h2>
                </div>
                <p className="block ml-4 mt-2">
                  I have been blessed by this community and have strived to continue to pay that forward by giving back as much as I can.
                  I volunteer whenever possible at Union Grove Elementary School, mentor and coach for the Union Grove Union High School
                  Business Incubator Program, student job shadows, have coached for multiple youth sports (Union Grove Rec, UGYBA,
                  and Bronco Select), a former board member of Bronco Select, and helping local businesses. In addition, I have led non-profit
                  hackathons (a hackathon is an event where people engage in rapid and collaborative engineering over a relatively short period)
                  to create creative timely solutions, given talks on the importance of STEM & Trade careers, and involved with MATC web
                  development curriculum just to name a few. Being connected to the people and community helps bring the community voice, and
                  encourages participation and transparency. Helps me to be a strong and effective school board member.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-10 mb-2">
            <p>
              In closing, I have the experience, skills, and passion to serve on the school board and make a positive
              difference for our students and our community. I hope to earn your trust and your vote in the
              upcoming election. Thank you for your consideration.
            </p>
          </div>
        </div>


        <div className="xl:col-span-3 col-span-1">
          <div className="flex">
            <p className="font-bold leading-6 tracking-tight text-gray-900 me-2">
              District Resident:
            </p>
            <p>1993-2008, 2013-present</p>
          </div>

          <div className="mt-4 flex">
            <p className="font-bold leading-6 tracking-tight text-gray-900 me-2">
              Education:
            </p>
            <p>Union Grove Elementary and Union Grove Union High School Graduate, Bachelors of Science in Computer
              Information Systems from University of Wisconsin - Oshkosh</p>
          </div>

          <div className="mt-4 flex">
            <p className="font-bold leading-6 tracking-tight text-gray-900 me-2">
              Current Profession:
            </p>
            <p>Engineering Leader at Northwestern Mutual</p>
          </div>
        </div>

      </div>


      {/*
Why have you chosen to seek election to the Union Grove Elementary School Board?
I have decided to run for school board because I want to continue to give back to a school and community that has done some much for me. I care deeply about the education and well-being of our students. I believe that every child deserves a high-quality, safe, and innovative learning environment that prepares them for the challenges and opportunities of the future.

As a parent of two children who attend Union Grove Elementary, as well as my spouse who is an educator, I have witnessed the dedication and professionalism of our teachers, staff, and administrators. I have also seen areas where there are opportunities we can improve and innovate, such as enhancing academic achievement, supporting student engagement & mental health, and strengthening community involvement and transparency.

If elected, I will work collaboratively with all stakeholders to address these issues and ensure that our schools are safe, supportive, and successful for all students. I will also advocate for adequate and sustainable funding, transparent and accountable governance, and effective, fact, and evidence-based curriculum, policies and practices.

What experience do you have that will help you best represent Union Grove Elementary School electorate?
As an former Union Grove student, parent, taxpayer, and homeowner I understand how important strong public schools are to our community. Being a technology leader professionally, in church leadership, and active member in the community. Actively engaged in the community by coaching for multiple youth sports, former board member of Bronco Select, volunteer coach and mentor for the UGUHS Business Incubator program, and helping local businesses. I know how to collaborate, exercise strong responsible fiscal management, and encourage continuous improvement. These experiences have given me the tools to work collaboratively with those who hold differing points of view to reach fact-based solutions to complex problems.

I have the experience, skills, and passion to serve on the school board and make a positive difference for our students and our community. I hope to earn your trust and your vote in the upcoming election. Thank you for your consideration.

  */}
    </div>
  );
}
