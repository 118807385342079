import { useEffect } from 'react';
import { titleHelper } from "../../utils/navigation";
import VolunteerSignup from "../../components/VolunteerSignup/VolunteerSignup";

export default function Help() {
  useEffect(() => {
    document.title = titleHelper('Help / Volunteer');
  }, []);

  return (
    <div className="bg-white/80 w-full flex justify-center justify-items-center py-10">
      <VolunteerSignup />
    </div>
  );
}