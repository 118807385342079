import { useState } from "react";

export default function VolunteerSignup() {

  const [numberLoaded, updateNumberLoaded] = useState(0);
  const defaultHeight = '1750px';

  function iframeResize() {
    const iframe = document.getElementById('volunteer-signup');
    const main = document.getElementById('main');
    updateNumberLoaded(numberLoaded + 1);
    if (iframe) {
      if (numberLoaded % 2 === 1) {
        iframe.height = '500px';
        // iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
      } else {
        iframe.height = defaultHeight;
      }
      main.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <iframe
      id="volunteer-signup"
      title="Volunteer Signup"
      src="https://docs.google.com/forms/d/e/1FAIpQLSfsxe_g_U6AQYv4rUzOzoAp8_qI7Awp9ZmWdnyDWTHM7FbIjA/viewform?embedded=true"
      width="100%"
      height={defaultHeight}
      onLoad={iframeResize}
    >
      Loading…
    </iframe>
  );
}