import $ from 'jquery';

export function autoScroll(elId, delay = 2000, transitionSpeed = 1000, anchorEl = '#main', scrollTop = null) {
  const offset = scrollTop ?? $(elId).offset().top;

  // console.log(
  //   `ELEMENT: ${elId}\n`,
  //   `DELAY: ${delay}\n`,
  //   `TRANSITION SPEED: ${transitionSpeed}\n`,
  //   `ANCHOR ELEMENT: ${anchorEl}\n`,
  //   `SCROLL TOP: ${offset}\n\n`,
  // );
  $(anchorEl).delay(delay).animate({
    scrollTop: offset
  }, transitionSpeed);
}