import { Routes, Route, useLocation } from 'react-router-dom';
import About from './views/AboutMe/AboutMe';
import CandidacyAnnouncement from './views/CandidacyAnnouncement/CandidacyAnnouncement';
import Home from './views/Home/Home';
import Help from './views/Help/Help';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import FullModal from './components/FullModal/FullModal';
import Background from './components/Background/Background';

export default function App() {
  const hideThankYou = !!(new URLSearchParams(useLocation().search)).get('hide-thank-you');

  return (
    <div className="bg-blue-800 relative isolate overflow-hidden ">
      <div id="container" className="flex flex-col">
        <Background />

        <Header />

        <main id="main" className="flex-grow overflow-scroll">

          {/* =========== VIEW SWITCH =========== */}
          <Routes>
            <Route path="/help" element={<Help />} />
            <Route path="/yard-sign" element={<Help />} />
            <Route path="/about" element={<About />} />
            <Route path="/candidacy-announcement" element={<CandidacyAnnouncement />} />
            <Route path="/*" element={<Home />} />
          </Routes>
          {!hideThankYou && <FullModal open={true} />}
          {/* =========== FOOTER =========== */}
          <Footer />

        </main>
      </div>
    </div>
  );
}