import { initializeApp } from 'firebase/app';
// import { getAuth } from "firebase/auth";
// import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

const config = {
  apiKey: "AIzaSyA30_FplrERRgRzEF0QKeF48VxuJ-Kq6R0",
  authDomain: "andrew-school-board.firebaseapp.com",
  projectId: "andrew-school-board",
  storageBucket: "andrew-school-board.appspot.com",
  messagingSenderId: "704052054126",
  appId: "1:704052054126:web:914b0f6f130ef8d8473e7f",
  measurementId: "G-71LQLCWCDH"
  // timestampsInSnapshots: true,
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
// export const db = getFirestore(app);
// export const auth = getAuth(app);
// export const timestamp = () => serverTimestamp();
export const log = (event) => logEvent(analytics, event);

