import { NavLink as ReactNavLink } from 'react-router-dom';
import { autoScroll } from '../../utils/animation';

export default function NavLink({ onClick, children, ...rest }) {
  return (
    <ReactNavLink
      onClick={() => {
        autoScroll('#main', 0, 1000, '#main', 0);
        onClick && onClick();
      }}
      {...rest}
    >
      {children}
    </ReactNavLink>
  );
}