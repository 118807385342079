import Announcement from "../../components/Announcement/Announcement";

export default function CandidacyAnnouncement() {

  return (
    <div id="candidacy-announcement" className="w-full bg-white px-8">

      <Announcement />

    </div>
  );
}