'use client';

import { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { HeartIcon } from '@heroicons/react/24/outline';

export default function FullModal(props) {
  const [open, setOpen] = useState(props.open);

  return (
    // set onClose to setOpen if you want it to close onClick
    <Dialog open={open} onClose={() => null || setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-blue-950 bg-opacity-95 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="
            w-3/4
            relative
            transform
            overflow-hidden
            rounded-lg
            bg-white
            px-4
            pb-4
            pt-5
            text-left
            shadow-xl
            transition-all
            data-[closed]:translate-y-4
            data-[closed]:opacity-0
            data-[enter]:duration-300
            data-[leave]:duration-200
            data-[enter]:ease-out
            data-[leave]:ease-in
            sm:my-8
            xxsm:w-full
            xxsm:max-w-sm
            sm:p-6
            data-[closed]:sm:translate-y-0
            data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                <HeartIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="mb-4 signature text-3xl font-extrabold leading-6 text-blue-900">
                  Thank You!
                </DialogTitle>
                <div className="mt-4">
                  <p className="text-sm text-gray-700 text-left mb-4">
                    I wanted to take a moment to thank you all for your support. I am deeply humbled by the number of people who
                    braved the Wisconsin “Spring” weather to turn out and vote.   Thank you for taking the time to make your voices
                    heard.
                  </p>
                  <p className="text-sm text-gray-700 text-left mb-4">
                    Thank you to those who sent encouraging words and spread the word, as well as to those I had the privilege of
                    speaking with personally. I appreciate your passion and concern for our students and schools. Even more, I
                    enjoyed getting to know more great people in this community that, without running for school board, I likely
                    wouldn’t have had the opportunity to meet.
                  </p>
                  <p className="text-sm text-gray-700 text-left mb-4">
                    The campaign was challenging, and I am indebted to my family and friends for their significant contributions.
                    Your efforts, from the physical tasks of putting up signs, walking with me, and distributing door hangers to
                    emotional support and encouragement, were instrumental in my success. I couldn’t have done this without you,
                    and I am truly grateful for your part in this journey.
                  </p>
                  <p className="text-sm text-gray-700 text-left mb-4">
                    In closing, I am grateful for this opportunity to serve on the Union Grove Elementary School Board and make a positive difference for our students and community.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <p className="inline-flex w-full justify-center">
                Sincerely,
              </p>
              <p className="mt-4 mb-2 -rotate-2 mt-2 inline-flex w-full justify-center signature text-3xl text-blue-900 font-bold text-center">Andrew Ninneman</p>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
