import americanFlag from '../../images/american-flag.jpg';

export default function Announcement() {

  return (
    <>
      <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-700 ring-1 ring-white/10 hover:ring-indigo/90">
            For additional information about me and my campaign. &nbsp;
            <a href="/about" className="font-semibold">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <h1 className="text-center text-4xl font-bold tracking-tight sm:text-6xl text-gray-900 ">
          Announcing my candidacy for the Union Grove Elementary School Board
        </h1>
        <p className="mt-6 text-lg leading-8 text-gray-800 text-justify">
          I am proud to announce my candidacy for the Union Grove Elementary School Board. Make sure to vote, April 2, 2024!
          <br /><br />
          As a graduate of both Union Grove Elementary School and Union Grove Union High School, longtime resident of Union Grove and and a committed member of this community, I am eager to bring my skills and experience to the table to make a positive impact on our school.
          <br /><br />
          I am looking forward to sharing more about myself, connecting with all of you (parents, students and community members), hearing your concerns, and sharing my passion and skills that would make me a strong addition to the School Board. Let’s work together to build on our strong school foundation and shape an even stronger Union Grove Elementary!
        </p>
        <div className="mt-40 flex items-center justify-center gap-x-6">
          <img
            src={americanFlag}
            alt="American Flag"
            className="
                w-[25vw]
                md:w-[20vw]
                sm:w-[20vw]
              "
          />
        </div>
      </div>
    </>
  );
}